import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";

import "./styles/reset.css";


import opensea from './assets/Opensea.png';
import NFT from './assets/NFT.glb';


function App() {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Select the amount.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .PublicMint(1)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 10) {
      newMintAmount = 10;
    }
    setMintAmount(newMintAmount);
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const [balance, setBalance] = useState(0);
  const blockchainState = useSelector((state) => state.blockchain);

  useEffect(() => {
    if (blockchainState.web3) {
      blockchainState.web3.eth.getBalance(blockchainState.account).then((balance) => {
        console.log(` ${balance.toString()}`);
        setBalance(balance.toString());
      });
    }
  }, [blockchainState.account, blockchainState.web3]);

  const whiteList = ['0x40E782fF25789501081A14826B51fcA34252F768', '0xC0C0225a5B21ffb2d7f869EA381a18E6D1ab93e4'];

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  const modelRef = React.useRef();
  

  return (

    <div className="BodyDapp">





      <div className="WalletBox">

        {blockchain.account === "" ||
          blockchain.smartContract === null ? (
          <>
            <div className="WalletButtom"
              onClick={(e) => {
                e.preventDefault();
                dispatch(connect());
                getData();
              }}
            >
              Connect Wallet
            </div>

          </>
        ) : (<>
          <div className="WalletButtom"
            onClick={(e) => {
              e.preventDefault();
              dispatch(connect());
              getData();
            }}
          >
            {blockchain.account.substring(0, 4)}......{blockchain.account.substring(blockchain.account.length - 3)}


          </div>
        </>
        )}


      </div>



      <div className="MainBox">
        <div className="ColBoxA">
          <div id="greenLine"></div>
          <div id="greenLine"></div>
          <h1 className="BoxTitle"> PLEX METAVERSE 3D NFTS</h1>
          <div className="Info"> MINTED: <span className="InfoBox">{data.totalSupply}/100.000</span></div>
          <div className="Info"> PRICE: <span className="InfoBox">1 MATIC</span></div>


          <div className="Links">
            <div className="TokenInfo"> <a target='_blank' href="https://polygonscan.com/token/0xe4c0bde15b5de200f2ab0b6be96066889ffb3f70"> TOKEN CONTRACT </a> </div>
            <div className="TokenInfo"> <a target='_blank' href="https://polygonscan.com/token/0x4bAD5d89a58797Bf72A51b03AF1dcb5E632c7dc1"> MINT CONTRACT </a> </div>
            <div className="TokenInfo"> <a target='_blank' href="https://opensea.io/collection/plex-metaverse-3d-nfts"> <img className="OpenseaLogo" src={opensea}></img> </a> </div>

          </div>
        </div>
        <div className="ColBoxB">

          <div className="NFTBox">
            <div className="Viewer">

              <model-viewer
                src={NFT}
                alt="A rock"
                exposure="0.4"
                auto-rotate="true" autoplay="true" camera-controls class="ModelScene--viewer" ar-status="not-presenting"
              >
              </model-viewer>
            </div>
          </div>
          <div className="NFTtitleBox" >
            <h1 className="NFTtitle">PLEX METAVERSE NFT</h1>
          </div>
        </div>
      </div>

      <div className="MintBox">
        <div className="MintButtom">
          <button className="ButtomStyle"
            disabled={blockchain.account === "" || blockchain.smartContract === null}
            onClick={(e) => {
              e.preventDefault();
              claimNFTs();
              getData();
            }}
          >
            {blockchain.account === "" || blockchain.smartContract === null
              ? "MINT NFT"
              : claimingNft
                ? "MINTING"
                : "MINT NFT"}
          </button>
        </div>
      </div>
    </div>

  );
}

export default App;
